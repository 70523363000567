// IMPORTS
import React from "react";
import { View, Text, Image, ImageBackground } from "react-native";
import { registerRootComponent } from "expo";
import { modelName } from "expo-device";
import { webStyles } from "./styles/webStyles";
import App from "./App";
import ReactNativeIcon from "./assets/icons/react-native.png";
import Background from "./assets/backgrounds/gradient.jpeg";
// COMPONENT
const Web = () => {
  // STATE
  const { screen, styles } = webStyles();
  // WEB VIEW
  const webView = () => {
    return (
      <View style={styles.webContainer}>
        {/* BACKGROUND */}
        <ImageBackground source={Background} style={styles.webBackground} />
        {/* DESCRIPTION */}
        <View style={styles.descriptionContainer}>
          <Text style={styles.descriptionTitle}>Flappy Bird</Text>
          <Text style={styles.descriptionText}>
            React Native
            <Image style={styles.descriptionIcon} source={ReactNativeIcon} />
          </Text>
        </View>
        {/* PHONE */}
        <View style={styles.phoneContainer}>
          <View style={styles.phoneNotch}>
            <View style={styles.phoneLense} />
            <View style={styles.phoneSpeaker} />
          </View>
          <App />
          <View style={styles.phoneBar} />
        </View>
      </View>
    );
  };
  // MOBILE VIEW
  const mobileView = () => {
    return <App />;
  };
  // RENDER
  return screen.width <= 750 || modelName === "iPad" ? mobileView() : webView();
};
// INITIALIZE
registerRootComponent(Web);
