// IMPORTS
import React, { Component } from "react";
import { View, Image } from "react-native";
import { sprites } from "../libraries/variables";
import { styles } from "../styles/componentStyles";
// COMPONENT
export default class Floor extends Component {
  render() {
    // STATE
    const height = this.props.body.bounds.max.y - this.props.body.bounds.min.y;
    const width = this.props.body.bounds.max.x - this.props.body.bounds.min.x;
    const x = this.props.body.position.x - width / 2;
    const y = this.props.body.position.y - height / 2;
    const imageIterations = Math.ceil(width / height);
    // RENDER
    return (
      <View style={[styles.floor, { height, width, top: y, left: x }]}>
        {Array.apply(null, Array(imageIterations)).map((_, index) => {
          return (
            <Image
              key={index}
              style={{ height, width: height }}
              resizeMode="stretch"
              source={sprites.floor}
            />
          );
        })}
      </View>
    );
  }
}
