// IMPORTS
import React, { Component } from "react";
import { Image } from "react-native";
import { sprites } from "../libraries/variables";
import { styles } from "../styles/componentStyles";
// PIPE TOP
export default class PipeTop extends Component {
  render() {
    // STATE
    const height = this.props.body.bounds.max.y - this.props.body.bounds.min.y;
    const width = this.props.body.bounds.max.x - this.props.body.bounds.min.x;
    const x = this.props.body.position.x - width / 2;
    const y = this.props.body.position.y - height / 2;
    // RENDER
    return (
      <Image
        style={[styles.pipeTop, { height, width, top: y, left: x }]}
        resizeMode="stretch"
        source={sprites.pipeTop}
      />
    );
  }
}
