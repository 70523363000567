// IMPORTS
import React, { Component } from "react";
import { View, Image } from "react-native";
import { sprites } from "../libraries/variables";
import { styles } from "../styles/componentStyles";
// COMPONENT
export default class Pipe extends Component {
  render() {
    // STATE
    const height = this.props.body.bounds.max.y - this.props.body.bounds.min.y;
    const width = this.props.body.bounds.max.x - this.props.body.bounds.min.x;
    const x = this.props.body.position.x - width / 2;
    const y = this.props.body.position.y - height / 2;
    const pipeRatio = 160 / width;
    const pipeHeight = 35 * pipeRatio;
    const pipeIterations = Math.ceil(height / pipeHeight);
    // RENDER
    return (
      <View
        style={[styles.pipe, { height: height, width: width, top: y, left: x }]}
      >
        {Array.apply(null, Array(pipeIterations)).map((_, index) => {
          return (
            <Image
              key={index}
              style={{ height: pipeHeight, width }}
              resizeMode="stretch"
              source={sprites.pipeCore}
            />
          );
        })}
      </View>
    );
  }
}
