// IMPORTS
import { StyleSheet } from "react-native";
import { screen, colors } from "../libraries/variables";
// STYLES
const styles = StyleSheet.create({
  // APP
  appContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    overflow: "hidden",
  },
  appBackground: {
    height: "100%",
    width: "100%",
  },
  // GAME
  gameContainer: {
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  // SCORE
  score: {
    position: "absolute",
    top: 50,
    color: colors.white,
    fontSize: 72,
    textShadowColor: colors.gray,
    textShadowOffset: { height: 2, width: 2 },
    textShadowRadius: 2,
  },
  // INSTRUCTION
  instruction: {
    position: "absolute",
    bottom: screen.height / 2 - 64,
    color: colors.white,
    fontSize: 24,
    textShadowColor: colors.gray,
    textShadowOffset: { height: 2, width: 2 },
    textShadowRadius: 2,
  },
  // GAME OVER
  gameOverContainer: {
    height: "100%",
    width: "100%",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.blackFade,
  },
  gameOverText: {
    color: colors.white,
    fontSize: 24,
    textShadowColor: colors.gray,
    textShadowOffset: { height: 2, width: 2 },
    textShadowRadius: 2,
    shadowColor: colors.gray,
    shadowOffset: { height: 2, width: 2 },
    shadowRadius: 2,
    borderColor: colors.white,
    borderWidth: 3,
    borderRadius: 15,
    padding: 10,
    marginTop: 50,
  },
  // TITLE
  title: {
    color: colors.white,
    fontSize: 48,
    textShadowColor: colors.gray,
    textShadowOffset: { height: 2, width: 2 },
    textShadowRadius: 2,
  },
  // TEXT
  text: {
    color: colors.white,
    fontSize: 24,
    textShadowColor: colors.gray,
    textShadowOffset: { height: 2, width: 2 },
    textShadowRadius: 2,
  },
  // WEB INPUT
  webInput: {
    height: "100%",
    width: "100%",
    position: "absolute",
  },
});
// EXPORT
export { styles };
