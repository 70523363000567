// IMPORTS
import React, { PureComponent } from "react";
import {
  Animated,
  Easing,
  Image as NativeImage,
  Platform,
  View,
} from "react-native";
// GET IMAGE DIMENSIONS
// const getImageDimensions = (source) => {
//   if (Platform.OS === "web") {
//     const img = new Image();
//     img.src = source;
//     return {
//       width: img.width,
//       height: img.height,
//     };
//   } else {
//     return NativeImage.resolveAssetSource(source);
//   }
// };
// COMPONENT
export default class SpriteSheet extends PureComponent {
  // DEFAULT PROPS
  static defaultProps = {
    columns: 1,
    rows: 1,
    animations: {},
    offsetY: 0,
    offsetX: 0,
  };
  // CONSTRUCTOR
  constructor(props) {
    super(props);
    this.state = {
      imageHeight: 0,
      imageWidth: 0,
      defaultFrameHeight: 0,
      defaultFrameWidth: 0,
      translateYInputRange: [0, 1],
      translateYOutputRange: [0, 1],
      translateXInputRange: [0, 1],
      translateXOutputRange: [0, 1],
    };
    this.time = new Animated.Value(0);
    this.interpolationRanges = {};
    let {
      source,
      height,
      width,
      rows,
      columns,
      frameHeight,
      frameWidth,
      offsetY,
      offsetX,
    } = this.props;
    // let dimensions = getImageDimensions(source);
    let dimensions = source.dimensions;
    let ratio = 1;
    let imageHeight = dimensions.height;
    let imageWidth = dimensions.width;
    offsetX = -offsetX;
    offsetY = -offsetY;
    frameHeight = frameHeight || dimensions.height / rows;
    frameWidth = frameWidth || dimensions.width / columns;
    if (width) {
      ratio = (width * columns) / dimensions.width;
      imageHeight = dimensions.height * ratio;
      imageWidth = width * columns;
      frameHeight = (dimensions.height / rows) * ratio;
      frameWidth = width;
    } else if (height) {
      ratio = (height * rows) / dimensions.height;
      imageHeight = height * rows;
      imageWidth = dimensions.width * ratio;
      frameHeight = height;
      frameWidth = (dimensions.width / columns) * ratio;
    }
    Object.assign(this.state, {
      imageHeight,
      imageWidth,
      frameHeight,
      frameWidth,
      offsetX,
      offsetY,
    });
    this.generateInterpolationRanges();
  }
  // GET FRAME POSITION
  getFramePosition = (i) => {
    let { columns, offsetX, offsetY } = this.props;
    let { frameHeight, frameWidth } = this.state;
    let currentColumn = i % columns;
    let xAdjust = -currentColumn * frameWidth;
    xAdjust -= offsetX;
    let yAdjust = -((i - currentColumn) / columns) * frameHeight;
    yAdjust -= offsetY;
    return {
      x: xAdjust,
      y: yAdjust,
    };
  };
  // GENERATE INTERPOLATION RANGES
  generateInterpolationRanges = () => {
    let { animations } = this.props;
    for (let key in animations) {
      let { length } = animations[key];
      let input = [].concat(...Array.from({ length }, (_, i) => [i, i + 1]));
      this.interpolationRanges[key] = {
        translateY: {
          in: input,
          out: [].concat(
            ...animations[key].map((i) => {
              let { y } = this.getFramePosition(i);
              return [y, y];
            })
          ),
        },
        translateX: {
          in: input,
          out: [].concat(
            ...animations[key].map((i) => {
              let { x } = this.getFramePosition(i);
              return [x, x];
            })
          ),
        },
      };
    }
  };
  // STOP
  stop = (cb) => {
    this.time.stopAnimation(cb);
  };
  // RESET
  reset = (cb) => {
    this.time.stopAnimation(cb);
    this.time.setValue(0);
  };
  // PLAY
  play = ({
    type,
    fps = 24,
    loop = false,
    resetAfterFinish = false,
    onFinish = () => {},
  }) => {
    let { animations } = this.props;
    let { length } = animations[type];
    this.setState({ animationType: type }, () => {
      let animation = Animated.timing(this.time, {
        toValue: length,
        duration: (length / fps) * 1000,
        easing: Easing.linear,
        useNativeDriver: Platform.OS === "web" ? false : true,
      });
      this.time.setValue(0);
      if (loop) {
        Animated.loop(animation).start();
      } else {
        animation.start(() => {
          if (resetAfterFinish) {
            this.time.setValue(0);
          }
          onFinish();
        });
      }
    });
  };
  // RENDER
  render() {
    let {
      imageHeight,
      imageWidth,
      frameHeight,
      frameWidth,
      animationType,
      offsetX,
      offsetY,
    } = this.state;
    let { viewStyle, imageStyle, source, onLoad } = this.props;
    let {
      translateY = { in: [0, 0], out: [offsetY, offsetY] },
      translateX = { in: [0, 0], out: [offsetX, offsetX] },
    } = this.interpolationRanges[animationType] || {};
    return (
      <View
        style={[
          viewStyle,
          {
            height: frameHeight,
            width: frameWidth,
            overflow: "hidden",
          },
        ]}
      >
        <Animated.Image
          source={source.image}
          onLoad={onLoad}
          style={[
            imageStyle,
            {
              height: imageHeight,
              width: imageWidth,
              transform: [
                {
                  translateX: this.time.interpolate({
                    inputRange: translateX.in,
                    outputRange: translateX.out,
                  }),
                },
                {
                  translateY: this.time.interpolate({
                    inputRange: translateY.in,
                    outputRange: translateY.out,
                  }),
                },
              ],
            },
          ]}
        />
      </View>
    );
  }
}
// AVAILABLE PROPS
// source: sourcePropType.isRequired // source must be required
// columns: PropTypes.number.isRequired,
// rows: PropTypes.number.isRequired,
// animations: PropTypes.object.isRequired, // see example
// viewStyle: stylePropType, // styles for the sprite sheet container
// imageStyle: stylePropType, // styles for the sprite sheet
// height: PropTypes.number, // set either height, width, or none,
// width: PropTypes.number, // but not both height and width
// onLoad: PropTypes.func,
// frameWidth: PropTypes.func, // overrides sprite size calculation based on height,width,rows,columns props
// frameHeight: PropTypes.func, // both frameWidth and frameHeight must be set or neither
// offsetX: PropTypes.func, // used with frameWidth/frameHeight to adjust offset of first frame
// offsetY: PropTypes.func
