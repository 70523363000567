// IMPORTS
import { Platform } from "react-native";
import Matter from "matter-js";
import Pipe from "../components/Pipe";
import PipeTop from "../components/PipeTop";
import { screen } from "./variables";
// STATE
let pipes = 0;
let pipeGap = 320;
let pipeWidth = 100;
// RANDOM BETWEEN
const randomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
// RESET PIPES
const resetPipes = () => {
  pipes = 0;
};
// GENERATE PIPES
const generatePipes = () => {
  let topPipeHeight = randomBetween(100, screen.height / 2 - 100);
  let bottomPipeHeight = screen.height - topPipeHeight - pipeGap;
  let sizes = [topPipeHeight, bottomPipeHeight];
  if (Math.random() < 0.5) {
    sizes = sizes.reverse();
  }
  return sizes;
};
// ADD PIPES
const addPipes = (x, world, entities) => {
  // STATE
  let [pipe1Height, pipe2Height] = generatePipes();
  let pipeTopWidth = pipeWidth + 20;
  let pipeTopHeight = (pipeTopWidth / 205) * 95
  pipe1Height = pipe1Height - pipeTopHeight;
  pipe2Height = pipe2Height - pipeTopHeight;
  // TOP PIPE
  let pipe1 = Matter.Bodies.rectangle(
    x,
    pipe1Height / 2,
    pipeWidth,
    pipe1Height,
    { isStatic: true }
  );
  let pipe1Top = Matter.Bodies.rectangle(
    x,
    pipe1Height + pipeTopHeight / 2,
    pipeTopWidth,
    pipeTopHeight,
    { isStatic: true }
  );
  // BOTTOM PIPE
  pipe2Height = pipe2Height - pipeTopHeight;
  let pipe2 = Matter.Bodies.rectangle(
    x,
    screen.height - 50 - pipe2Height / 2,
    pipeWidth,
    pipe2Height,
    { isStatic: true }
  );
  let pipe2Top = Matter.Bodies.rectangle(
    x,
    screen.height - 50 - pipe2Height - pipeTopHeight / 2,
    pipeTopWidth,
    pipeTopHeight,
    { isStatic: true }
  );
  // ADD PIPES TO WORLD
  Matter.World.add(world, [pipe1, pipe1Top, pipe2, pipe2Top]);
  // SET PIPES
  entities[`pipe${pipes + 1}`] = {
    body: pipe1,
    renderer: Pipe,
    scored: false,
  };
  entities[`pipe${pipes + 2}`] = {
    body: pipe2,
    renderer: Pipe,
    scored: false,
  };
  entities[`pipe${pipes + 1}Top`] = {
    body: pipe1Top,
    renderer: PipeTop,
    scored: false,
  };
  entities[`pipe${pipes + 2}Top`] = {
    body: pipe2Top,
    renderer: PipeTop,
    scored: false,
  };
  pipes += 2;
};
// GAME LOOP
const gameLoop = (entities, { touches, events, time, dispatch }) => {
  // STATE
  let engine = entities.physics.engine;
  let world = entities.physics.world;
  let bird = entities.bird.body;
  let hadInput = false;
  const inputType = Platform.OS === "web" ? events : touches;
  // HANDLE INPUT
  inputType
    .filter((input) => input.type === "press")
    .forEach((_) => {
      if (!hadInput) {
        // START GAME
        if (world.gravity.y === 0.0) {
          engine.gravity.y = 1.2;
          addPipes(screen.width * 2 - pipeWidth / 2, world, entities);
          addPipes(screen.width * 3 - pipeWidth / 2, world, entities);
          dispatch({ type: "hasStarted" });
        }
        // FLY UP
        hadInput = true;
        Matter.Body.setVelocity(bird, {
          x: bird.velocity.x,
          y: -10,
        });
      }
    });
  // UPDATE ENGINE
  Matter.Engine.update(engine, time.delta);
  // HANDLE OBJECTS
  Object.keys(entities).forEach((key) => {
    if (key.indexOf("pipe") === 0 && entities.hasOwnProperty(key)) {
      // MOVE ALL OBJECTS
      Matter.Body.translate(entities[key].body, { x: -2, y: 0 });
      // PIPE LOGIC
      if (
        key.indexOf("Top") !== -1 &&
        parseInt(key.replace("pipe", "")) % 2 === 0
      ) {
        // SCORE POINT
        if (
          entities[key].body.position.x <= bird.position.x &&
          !entities[key].scored
        ) {
          entities[key].scored = true;
          dispatch({ type: "score" });
        }
        // SPAWN MORE PIPES
        if (entities[key].body.position.x <= -1 * (pipeWidth / 2)) {
          let pipeIndex = parseInt(key.replace("pipe", ""));
          delete entities["pipe" + (pipeIndex - 1) + "Top"];
          delete entities["pipe" + (pipeIndex - 1)];
          delete entities["pipe" + pipeIndex + "Top"];
          delete entities["pipe" + pipeIndex];
          addPipes(screen.width * 2 - pipeWidth / 2, world, entities);
        }
      }
    }
    // FLOOR LOGIC
    else if (key.indexOf("floor") === 0) {
      // SPAWN MORE FLOOR
      if (entities[key].body.position.x <= (-1 * screen.width) / 2) {
        Matter.Body.setPosition(entities[key].body, {
          x: screen.width + screen.width / 2,
          y: entities[key].body.position.y,
        });
      } else {
        // MOVE FLOOR
        Matter.Body.translate(entities[key].body, { x: -2, y: 0 });
      }
    }
  });
  return entities;
};
// EXPORTS
export { resetPipes, gameLoop };
