// IMPORTS
import React, { Component } from "react";
import {
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ImageBackground,
  Platform,
} from "react-native";
import { registerRootComponent } from "expo";
import { GameEngine } from "react-native-game-engine";
import Matter from "matter-js";
import { styles } from "./styles/appStyles";
import { resetPipes, gameLoop } from "./libraries/methods";
import { screen, sprites } from "./libraries/variables";
import Bird from "./components/Bird";
import Floor from "./components/Floor";
// COMPONENT
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasStarted: false,
      running: true,
      score: 0,
    };
    this.birdRef = null;
    this.gameEngine = null;
    this.entities = this.setupWorld();
  }
  // SET BIRD REF
  setBirdRef = (ref) => {
    this.birdRef = ref;
    this.birdRef.play({
      type: "flap",
      fps: 12,
      loop: true,
    });
  };
  // SET UP WORLD
  setupWorld = () => {
    let engine = Matter.Engine.create({ enableSleeping: false });
    let world = engine.world;
    engine.gravity.y = 0.0;
    // BIRD
    let bird = Matter.Bodies.rectangle(
      screen.width / 2,
      screen.height / 2,
      50,
      41
    );
    // CEILING
    let ceiling = Matter.Bodies.rectangle(0, -10, screen.width, 10, {
      isStatic: true,
    });
    // FLOOR 1
    let floor1 = Matter.Bodies.rectangle(
      screen.width / 2,
      screen.height - 25,
      screen.width + 4,
      50,
      { isStatic: true }
    );
    // FLOOR 2
    let floor2 = Matter.Bodies.rectangle(
      screen.width + screen.width / 2,
      screen.height - 25,
      screen.width + 4,
      50,
      { isStatic: true }
    );
    // ADD OBJECTS TO WORLD
    Matter.Composite.add(world, [bird, ceiling, floor1, floor2]);
    // HANDLE COLLISIONS
    Matter.Events.on(engine, "collisionStart", (event) => {
      this.gameEngine.dispatch({ type: "gameOver" });
    });
    // RETURN
    return {
      physics: { engine, world },
      floor1: { body: floor1, renderer: Floor },
      floor2: { body: floor2, renderer: Floor },
      bird: {
        body: bird,
        renderer: Bird,
        setBirdRef: this.setBirdRef,
      },
    };
  };
  // RESET
  reset = () => {
    resetPipes();
    this.gameEngine.swap(this.setupWorld());
    this.birdRef.play({
      type: "flap",
      fps: 12,
      loop: true,
    });
    this.setState({
      running: true,
      score: 0,
    });
  };
  // RENDER
  render() {
    return (
      <View style={styles.appContainer}>
        {/* BACKGROUND */}
        <ImageBackground
          style={styles.appBackground}
          source={sprites.background}
        />
        {/* GAME */}
        <GameEngine
          ref={(ref) => {
            this.gameEngine = ref;
          }}
          style={styles.gameContainer}
          systems={[gameLoop]}
          running={this.state.running}
          onEvent={(event) => {
            switch (event.type) {
              case "hasStarted":
                return this.setState({ hasStarted: true });
              case "gameOver": {
                this.birdRef.play({ type: "idle" });
                return this.setState({ hasStarted: false, running: false });
              }
              case "score":
                return this.setState({ score: this.state.score + 1 });
              default:
                break;
            }
          }}
          entities={this.entities}
        >
          <StatusBar hidden={true} />
        </GameEngine>
        {/* SCORE */}
        <Text style={styles.score}>{this.state.score}</Text>
        {/* INSTRUCTION */}
        {this.state.running && !this.state.hasStarted && (
          <Text style={styles.instruction}>Tap anywhere to Start</Text>
        )}
        {/* GAME OVER BUTTON */}
        {!this.state.running && (
          <View style={styles.gameOverContainer}>
            <Text style={styles.title}>Game Over</Text>
            <TouchableOpacity onPress={this.reset}>
              <Text style={styles.gameOverText}>Tap here to Reset</Text>
            </TouchableOpacity>
          </View>
        )}
        {/* WEB INPUT */}
        {this.state.running && Platform.OS === "web" && (
          <TouchableWithoutFeedback
            onPress={() => {
              this.gameEngine.dispatch({ type: "press" });
            }}
          >
            <View style={styles.webInput} />
          </TouchableWithoutFeedback>
        )}
      </View>
    );
  }
}
// INITIALIZE
registerRootComponent(App);
