// IMPORTS
import { StyleSheet } from "react-native";
// STYLES
const styles = StyleSheet.create({
  floor: {
    position: "absolute",
    overflow: "hidden",
    flexDirection: "row",
  },
  pipe: {
    position: "absolute",
    overflow: "hidden",
  },
  pipeTop: {
    position: "absolute",
  },
});
// EXPORT
export { styles };
