// IMPORTS
import React, { Component } from "react";
import { Animated } from "react-native";
import SpriteSheet from "../libraries/spriteSheet";
import { sprites } from "../libraries/variables";
// COMPONENT
export default class Bird extends Component {
  constructor(props) {
    super(props);
    this.bird = null;
    this.animatedValue = new Animated.Value(this.props.body.velocity.y);
  }
  // LIFE CYCLE
  componentDidMount() {
    this.props.setBirdRef(this.bird);
  }
  render() {
    // STATE
    const height = this.props.body.bounds.max.y - this.props.body.bounds.min.y;
    const width = this.props.body.bounds.max.x - this.props.body.bounds.min.x;
    const x = this.props.body.position.x - width / 2;
    const y = this.props.body.position.y - height / 2;
    this.animatedValue.setValue(this.props.body.velocity.y);
    let rotation = this.animatedValue.interpolate({
      inputRange: [-10, 0, 10, 20],
      outputRange: ["-20deg", "0deg", "15deg", "45deg"],
      extrapolate: "clamp",
    });
    // RENDER
    return (
      <Animated.View
        style={{
          height,
          width,
          top: y,
          left: x,
          transform: [{ rotate: rotation }],
        }}
      >
        <SpriteSheet
          ref={(ref) => (this.bird = ref)}
          source={{
            image: sprites.bird,
            dimensions: {
              height: 100,
              width: 600,
            },
          }}
          columns={5}
          rows={1}
          width={60}
          animations={{
            idle: [0],
            flap: [0, 1, 2, 3],
          }}
        />
      </Animated.View>
    );
  }
}
